<template>
  <div v-if="isInit" class="chart-container">
    <div class="chartjs-size-monitor">
      <canvas ref="chart" class="chart" :style="`display: block; width: ${width}; height: ${height};`"></canvas>
    </div>
  </div>
</template>

<script>
import formatsCurrency from '@/mixins/formatsCurrency'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default {
  mixins: [formatsCurrency],
  watch: {
    loading: {
      handler (_, state) {
        if (state) {
          this.createChart()

          if (!this.isInit) {
            this.isInit = true
          }
        }
      },
      immmediate: true,
      deep: true
    }
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: 'auto'
    },
    height: {
      type: String,
      required: false,
      default: 'auto'
    },
    chartData: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    options: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      chart: null,
      isInit: false
    }
  },
  methods: {
    createChart () {
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.destroy()
        }

        if (this.$refs.chart) {
          const context = this.$refs.chart.getContext('2d')
          context.clearRect(0, 0, this.$refs.chart.width, this.$refs.chart.height)

          const plugin = {
            id: 'text-middle',
            beforeDraw: (chart) => {
              const width = chart.width
              const height = chart.height
              const ctx = chart.ctx

              ctx.restore()
              const fontSize = (height / 80).toFixed(2)
              ctx.font = fontSize + 'em sans-serif'
              ctx.textBaseline = 'middle'

              const text = this.$number(this.chartData.datasets[0].data.reduce((count, value) => count + +value, 0))
              const textX = Math.round((width - ctx.measureText(text).width) / 2)
              const textY = height / 1.9

              ctx.fillText(text, textX, textY)
              ctx.save()
            }
          }

          this.chart = new Chart(this.$refs.chart, {
            type: 'doughnut',
            plugins: [plugin],
            options: {
              responsive: true,
              onHover: (_, item) => {
                this.$emit('hover', item)
              },
              legend: {
                display: false
              },
              ...this.options
            },
            data: this.chartData
          })
        }
      })
    }
  },
  created () {
    this.createChart()
  }
}
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  margin: 0 auto;
}

.chart {
  padding: 0;
  margin: auto;
  display: block;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}
</style>
