<template>
  <div class="card flex-fill">
    <skeleton-loader v-if="loading" width="100%" height="100%" />
    <div class="card-body py-4">
      <div class="d-flex align-items-start">
        <div class="flex-grow-1">
          <div class="d-inline-flex">
            <slot name="header" />
            <icon-tooltip v-if="hasError"
                          class="text-danger ml-1"
                          variant="danger"
                          icon="exclamation-circle"
                          tooltipText="Une erreur s'est produite. Veuillez réessayer."
                          placement="topright" />
          </div>
          <p class="mb-2">
            <slot name="subtitle" />
          </p>

          <slot name="footer" />
        </div>
        <div class="d-inline-block ms-3">
          <div class="d-flex align-items-center">
            <slot name="icons" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTooltip from '@/components/common/IconTooltip'
import SkeletonLoader from '@/components/common/SkeletonLoader'

export default {
  components: {
    SkeletonLoader,
    IconTooltip
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
