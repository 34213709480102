import { render, staticRenderFns } from "./MessagesCards.vue?vue&type=template&id=4a8c858c&scoped=true&"
import script from "./MessagesCards.vue?vue&type=script&lang=js&"
export * from "./MessagesCards.vue?vue&type=script&lang=js&"
import style0 from "./MessagesCards.vue?vue&type=style&index=0&id=4a8c858c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a8c858c",
  null
  
)

export default component.exports