<template>
  <statistic-card :has-error="hasError"
                  :loading="loading"
                  class="commission-rate-container"
                  :class="{'promo-container' : hasCommissionRateDiscount}">
    <template v-slot:header>
      <h3 class="mb-2 commission-rate">
        {{ $number(commissionRateValue) }}
      </h3>
      <label v-if="hasCommissionRateDiscount" class="ml-2 commission-rate small" style="text-decoration: line-through">
        {{ $number(10) }}
      </label>
    </template>

    <template v-slot:subtitle>
      Taux de commission
    </template>

    <template v-slot:footer>
      <div v-if="shouldDisplayEndDate" class="d-flex align-items-center small">
        <fa-icon :icon="['far', 'clock']" class="mr-2" />
        <span>Jusqu'au {{ $shortDate(commissionRate.endDate) }}</span>
      </div>
    </template>

    <template v-slot:icons>
      <label v-if="hasCommissionRateDiscount" class="badge badge-info text-white mb-0 mr-2">Promo</label>
      <fa-icon :icon="['fas', 'tag']" size="lg" />
    </template>

  </statistic-card>
</template>

<script>
import StatisticCard from '@/components/common/StatisticCard'
import { isEmpty } from 'lodash-es'
import { isAfter, isBefore, isSameDay } from 'date-fns'
import formatsCurrency from '@/mixins/formatsCurrency'
import formatsDate from '@/mixins/formatsDate'
import userData from '@/mixins/user-data'

// TODO improve this by making this dynamic via an api response
const DEFAULT_COMMISSION_RATE = 10

export default {
  mixins: [userData, formatsCurrency, formatsDate],
  components: {
    StatisticCard
  },
  data () {
    return {
      hasError: false,
      loading: false
    }
  },
  computed: {
    commissionRate () {
      return this.$store.state.commissionRate.data
    },
    commissionRateValue () {
      return this.commissionRate ? this.commissionRate.rate : DEFAULT_COMMISSION_RATE
    },
    hasCommissionRateDiscount () {
      if (isEmpty(this.commissionRate) || this.commissionRate.rate === DEFAULT_COMMISSION_RATE) {
        return false
      }

      const { startDate } = this.commissionRate

      if (!startDate) {
        return true
      }

      const today = new Date()

      return isAfter(today, new Date(startDate)) || isSameDay(today, new Date(startDate))
    },
    shouldDisplayEndDate () {
      if (!this.commissionRate) {
        return false
      }

      const today = new Date()
      const { endDate } = this.commissionRate

      return isBefore(today, new Date(endDate)) || isSameDay(today, new Date(endDate))
    }
  },
  methods: {
    async fetchCommissionRate () {
      try {
        this.loading = true
        this.hasError = false
        await this.$store.dispatch('commissionRate/fetch', this.currentStoreId)
      } catch (e) {
        if (e.response.status === 404) {
          return
        }

        this.hasError = true
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    await this.fetchCommissionRate()
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 30px;
}

.commission-rate-container {
  &.promo-container {
    background-image: linear-gradient(to right, #0f1d2d, #010e1f);
    color: #fff;
  }

  .commission-rate {
    &::after {
      content: '%';
      margin-left: 2px;
    }
  }
}
</style>
