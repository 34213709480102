<template>
  <div v-if="isInit" class="chart-container" :style="`width: ${width}; height: ${height};`">
    <canvas :style="`max-height: ${height};`" ref="chart" class="chart"></canvas>
  </div>
</template>

<script>
import formatsCurrency from '@/mixins/formatsCurrency'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default {
  mixins: [formatsCurrency],
  watch: {
    loading: {
      handler (_, state) {
        if (state) {
          this.createChart()

          if (!this.isInit) {
            this.isInit = true
          }
        }
      },
      immmediate: true,
      deep: true
    }
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: '200px'
    },
    height: {
      type: String,
      required: false,
      default: '200px'
    },
    chartData: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    options: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      chart: null,
      isInit: false
    }
  },
  methods: {
    createChart () {
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.destroy()
        }

        if (this.$refs.chart) {
          const context = this.$refs.chart.getContext('2d')
          context.clearRect(0, 0, this.$refs.chart.width, this.$refs.chart.height)

          this.chart = new Chart(this.$refs.chart, {
            type: 'line',
            options: {
              onHover: (_, item) => {
                this.$emit('hover', item)
              },
              legend: {
                display: false
              },
              ...this.options
            },
            data: this.chartData
          })
        }
      })
    }
  },
  created () {
    this.createChart()
  }
}
</script>

<style lang="scss" scoped>
.chart-container {
  position:relative;
  margin: 0 auto;
}

.chart {
  padding: 0;
  margin: auto;
  display: block;
}

canvas {
  width: 100%;
  height: 100%;
}
</style>
