<template>
  <div class="border-bottom pb-2 mt-2 mb-4 d-flex align-items-center justify-content-between cursor-pointer" @click="handleClick">
    <h2>{{ title }}</h2>
    <fa-icon :icon="['fas', 'chevron-down']" class="mr-2 collapse-icon" :class="{ 'open': collapsed }"></fa-icon>
  </div>
</template>

<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.collapse-icon {
  transition: transform 0.3s;
  transform: rotate(0);

  &.open {
    transform: rotate(180deg);
  }
}
</style>
