<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid w-100">
      <template v-if="!isRegistrationCompleted && !hasSkippedOnboarding">
        <start-onboarding-message />
      </template>

      <template v-else>
        <onboarding-alert />
        <shipping-settings-alert />

        <div >
          <div class="row">
            <div class="col-12 col-xl-6 col-xxl-5 d-flex">
              <welcome-card />
            </div>
            <div class="col-12 d-flex col-xl-6 col-xxl-7">
              <products-card />
            </div>
          </div>

          <collapsible-header title="Vos statistiques" @click="collapseStats = !collapseStats" :collapsed="collapseStats" />
          <b-collapse v-model="collapseStats">
            <div class="row">
              <div class="col-12 col-xl-6 d-flex">
                <div class="w-100">
                  <stats-cards />
                  <div class="row">
                    <div class="col-6 d-flex">
                      <commission-rate-card />
                    </div>

                    <div class="col-6 d-flex">
                      <connected-store-card />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <graph-card />
              </div>
            </div>
          </b-collapse>

          <collapsible-header title="Vos messages" @click="collapseMessages = !collapseMessages" :collapsed="collapseMessages" />
          <b-collapse v-model="collapseMessages">
            <messages-cards />
          </b-collapse>
        </div>

      </template>
    </div>
  </app-layout>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import onboarding from '@/mixins/onboarding'
import CollapsibleHeader from '@/components/common/CollapsibleHeader'
import MessagesCards from '@/components/dashboard/MessagesCards'
import WelcomeCard from '@/components/dashboard/WelcomeCard'
import ProductsCard from '@/components/dashboard/ProductsCard'
import GraphCard from '@/components/dashboard/GraphCard'
import OnboardingAlert from '@/components/dashboard/alerts/OnboardingAlert'
import ShippingSettingsAlert from '@/components/dashboard/alerts/ShippingSettingsAlert'
import StatsCards from '@/components/dashboard/StatsCards'
import ConnectedStoreCard from '@/components/dashboard/ConnectedStoreCard'
import CommissionRateCard from '@/components/dashboard/CommissionRateCard'
import StartOnboardingMessage from '@/components/onboarding/StartOnboardingMessage'

export default {
  mixins: [onboarding],
  components: {
    MessagesCards,
    ShippingSettingsAlert,
    OnboardingAlert,
    BCollapse,
    GraphCard,
    CollapsibleHeader,
    ProductsCard,
    WelcomeCard,
    StatsCards,
    ConnectedStoreCard,
    CommissionRateCard,
    StartOnboardingMessage
  },
  data () {
    return {
      collapseStats: true,
      collapseMessages: true
    }
  }
}
</script>
