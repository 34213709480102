<template>
  <div class="row">
    <div class="col-12 col-xl-6">
      <message-card class="requests-card"
                    title="Demandes de support ouvertes"
                    href="/support/requests"
                    link-label="Voir toutes les demandes"
                    :loading="supportRequestLoading"
                    :data="openSupportRequestsData"
                    :is-empty="!openSupportRequests || !openSupportRequests.data.length"
                    empty-message="Aucune demande de support ouverte"
                    :error-message="supportRequestErrorMessage"
                    :total="openSupportRequestsTotal"
                    :item-link-label="`Voir le reste des ${openSupportRequestsTotal} demandes ouvertes`"
                    @navigate="handleNavigateToSupportRequests">
        <template v-slot:footer>
          <div class="mt-5">
            <label class="small text-muted">
              N'hésitez pas à nous contacter si vous avez des questions, des suggestions ou encore pour nous proposer de nouvelles fonctionnalités.
            </label>
            <message-composer @submit="sendMessage" />
          </div>
        </template>
      </message-card>
    </div>

    <div class="col-12 col-xl-6 d-flex">
      <div class="w-100">
        <div class="row">
          <div class="col-12 d-flex">
            <message-card class="messages-card"
                          title="Messages clients en attente"
                          href="/customer-messages"
                          link-label="Voir tous les messages"
                          :loading="clientMessagesLoading"
                          :data="recentClientMessagesData"
                          :is-empty="!recentClientMessages || !recentClientMessages.data.length"
                          empty-message="Aucun message d'une commande en attente"
                          :error-message="clientMessagesErrorMessage"
                          :total="recentClientMessagesTotal"
                          :item-link-label="`Voir le reste des ${recentClientMessagesTotal} messages en attente`"
                          @navigate="handleNavigateToClientMessage">
=            </message-card>
          </div>
          <div class="col-12 d-flex">
            <message-card class="questions-card"
                          title="Questions utilisateurs à traiter"
                          href="/product-questions"
                          link-label="Voir toutes les questions"
                          :loading="productQuestionsLoading"
                          :data="pendingProductQuestionsData"
                          :is-empty="!pendingProductQuestions || !pendingProductQuestions.data.length"
                          empty-message="Aucune question sur un produit à traiter"
                          :error-message="productQuestionsErrorMessage"
                          :total="pendingProductQuestionsTotal"
                          :item-link-label="`Voir le reste des ${pendingProductQuestionsTotal} questions à traiter`"
                          @navigate="handleNavigateToProductQuestion">
            </message-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEmpty, orderBy } from 'lodash-es'
import http from '@/utils/http'
import userData from '@/mixins/user-data'
import MessageCard from '@/components/dashboard/MessageCard'
import MessageComposer from '@/components/common/MessageComposer'

export default {
  mixins: [userData],
  components: {
    MessageCard,
    MessageComposer
  },
  data () {
    return {
      openSupportRequests: null,
      recentClientMessages: null,
      pendingProductQuestions: null,
      supportRequestLoading: false,
      clientMessagesLoading: false,
      productQuestionsLoading: false,
      supportRequestErrorMessage: null,
      clientMessagesErrorMessage: null,
      productQuestionsErrorMessage: null
    }
  },
  computed: {
    openSupportRequestsData () {
      if (!this.openSupportRequests) {
        return []
      }

      return this.openSupportRequests.data.map(request => ({
        id: request.id,
        title: request.reference,
        createdAt: request.createdAt,
        label: 'Demande :',
        message: () => this.getFirstSupportRequestMessage(request)
      }))
    },
    openSupportRequestsTotal () {
      return this.openSupportRequests ? this.openSupportRequests.total : 0
    },
    recentClientMessagesData () {
      if (!this.recentClientMessages) {
        return []
      }

      return this.recentClientMessages.data.map(message => ({
        id: message.orderId,
        title: `${message.order.customer.firstName} ${message.order.customer.lastName}`,
        subtitle: message.order.reference,
        createdAt: message.createdAt,
        label: 'Message :',
        message: message.text
      }))
    },
    recentClientMessagesTotal () {
      return this.recentClientMessages ? this.recentClientMessages.total : 0
    },
    pendingProductQuestionsData () {
      if (!this.pendingProductQuestions) {
        return []
      }

      return this.pendingProductQuestions.data.map(question => ({
        id: question.id,
        title: `${question.user.firstName} ${question.user.lastName}`,
        subtitle: question.product.name,
        createdAt: question.createdAt,
        label: 'Question :',
        message: question.question
      }))
    },
    pendingProductQuestionsTotal () {
      return this.pendingProductQuestions ? this.pendingProductQuestions.total : 0
    }
  },
  methods: {
    handleNavigateToSupportRequests (data) {
      this.$router.push(`/support/requests/${data.id}`)
    },
    handleNavigateToClientMessage (data) {
      this.$router.push(`/customer-messages/${data.id}`)
    },
    handleNavigateToProductQuestion (data) {
      this.$router.push(`/product-questions/${data.id}`)
    },
    getFirstSupportRequestMessage (request) {
      if (isEmpty(request.messages)) {
        return 'Message vide'
      }

      return orderBy(request.messages, ['createdAt'], ['asc'])[0].text
    },
    async sendMessage (message, onSuccess, onError) {
      try {
        const { data } = await http.post('/v1/support/requests', {
          message,
          storeId: this.currentStoreId
        })

        const clone = this.openSupportRequests ? cloneDeep(this.openSupportRequests) : {}
        this.openSupportRequests = { ...clone, data: [data, ...clone.data] }

        onSuccess()
      } catch {
        onError()
        this.$toasted.error('Erreur lors de l\'envoi. Veuillez réessayer.')
      }
    },
    async fetchSupportRequests () {
      try {
        this.supportRequestLoading = true
        this.supportRequestErrorMessage = null

        const { data: paginatedData } = await http.get(
          '/v1/support/requests',
          {
            params: {
              storeId: this.currentStoreId,
              userId: this.userId,
              page: 1,
              perPage: 5,
              status: 'open'
            }
          }
        )

        this.openSupportRequests = paginatedData
      } catch {
        this.supportRequestErrorMessage = "Une erreur s'est produite. Veuillez réessayer."
      } finally {
        this.supportRequestLoading = false
      }
    },
    async fetchClientsMessages () {
      try {
        this.clientMessagesLoading = true
        this.clientMessagesErrorMessage = null

        const { data: paginatedData } = await http.get(
          `/v1/stores/${this.currentStoreId}/customer-messages`,
          { params: { page: 1, perPage: 3, status: 'isUnreadByMerchant' } })

        this.recentClientMessages = paginatedData
      } catch {
        this.clientMessagesErrorMessage = "Une erreur s'est produite. Veuillez réessayer."
      } finally {
        this.clientMessagesLoading = false
      }
    },
    async fetchProductQuestions () {
      try {
        this.productQuestionsLoading = true
        this.productQuestionsErrorMessage = null

        const { data: paginatedData } = await http.get(`/v1/stores/${this.currentStoreId}/product-questions`, {
          params: { page: 1, perPage: 3, status: 'pending' }
        })

        this.pendingProductQuestions = paginatedData
      } catch {
        this.productQuestionsErrorMessage = "Une erreur s'est produite. Veuillez réessayer."
      } finally {
        this.productQuestionsLoading = false
      }
    }
  },
  async created () {
    await this.fetchSupportRequests()
    await this.fetchClientsMessages()
    await this.fetchProductQuestions()
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 30px;
}
.requests-card {
  min-height: 260px;
  max-height: 520px;
}
.messages-card {
  max-height: 300px;
}
.questions-card {
  max-height: 300px;
}
</style>
