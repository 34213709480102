<template>
  <div class="w-100">
    <div class="row">
      <div class="col-6 d-flex">
        <statistic-card :loading="loading" :has-error="hasError">
          <template v-slot:header>
            <h3 class="mb-2">{{ $number(data.soldProducts) }}</h3>
          </template>

          <template v-slot:subtitle>
            Produits vendus
          </template>

          <template v-slot:footer>
            <div v-if="data.soldProductsGrowth < 0 || data.soldProductsGrowth > 0" class="mb-0 d-flex align-items-center">
              <label class="badge growth mb-0" :class="+data.soldProductsGrowth < 0 ? 'badge-danger' : 'badge-success positive-growth'">
                {{ $number(data.soldProductsGrowth) }}
              </label>
              <p class="ml-1 text-muted small mb-0 text-nowrap">Depuis le mois passé</p>
            </div>
          </template>

          <template v-slot:icons>
            <fa-icon :icon="['fas', 'shopping-basket']" size="lg" />
          </template>
        </statistic-card>
      </div>

      <div class="col-6 d-flex">
        <statistic-card :loading="loading" :has-error="hasError">
          <template v-slot:header>
            <h3 class="mb-2">{{ $money(data.completedPayouts) }}</h3>
          </template>

          <template v-slot:subtitle>
            Total des revenus
          </template>

          <template v-slot:footer>
            <div v-if="data.transfersGrowth < 0 || data.transfersGrowth > 0" class="mb-0 d-flex align-items-center">
              <label class="badge growth mb-0" :class="+data.transfersGrowth < 0 ? 'badge-danger' : 'badge-success positive-growth'">
                {{ $number(data.transfersGrowth) }}
              </label>
              <p class="ml-1 text-muted small mb-0 text-nowrap">Depuis le mois passé</p>
            </div>
          </template>

          <template v-slot:icons>
            <fa-icon :icon="['fas', 'coins']" size="lg" />
          </template>
        </statistic-card>
      </div>
    </div>

    <div class="row">
      <div class="col-6 d-flex">
        <statistic-card :loading="loading" :has-error="hasError">
          <template v-slot:header>
            <h3 class="mb-2">{{ $number(data.pendingOrders) }}</h3>
          </template>

          <template v-slot:subtitle>
            Commandes en attentes
          </template>

          <template v-slot:footer>
            <router-link to="/orders" class="small">
              <fa-icon :icon="['fas', 'link']" class="mr-2" /> <span>Traiter les commandes</span>
            </router-link>
          </template>

          <template v-slot:icons>
            <fa-icon :icon="['fas', 'boxes']" size="lg" />
          </template>
        </statistic-card>
      </div>

      <div class="col-6 d-flex">
        <statistic-card :loading="loading" :has-error="hasError">
          <template v-slot:header>
            <h3 class="mb-2">{{ $number(data.completedOrders) }}</h3>
          </template>

          <template v-slot:subtitle>
            Commandes traitées
          </template>

          <template v-slot:footer>
            <div v-if="data.orderGrowth < 0 || data.orderGrowth > 0" class="mb-0 d-flex align-items-center">
              <label class="badge growth mb-0" :class="+data.orderGrowth < 0 ? 'badge-danger' : 'badge-success positive-growth'">
                <span class="align-middle">{{ $number(data.orderGrowth) }}</span>
              </label>
              <p class="ml-1 text-muted small mb-0 text-nowrap">Depuis le mois passé</p>
            </div>
          </template>

          <template v-slot:icons>
            <fa-icon :icon="['fas', 'truck-loading']" size="lg" />
          </template>
        </statistic-card>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/http'
import StatisticCard from '@/components/common/StatisticCard'
import formatsCurrency from '@/mixins/formatsCurrency'
import userData from '@/mixins/user-data'

export default {
  mixins: [userData, formatsCurrency],
  components: {
    StatisticCard
  },
  data () {
    return {
      data: {
        soldProducts: 0,
        completedPayouts: 0,
        pendingOrders: 0,
        completedOrders: 0,
        orderGrowth: 0,
        soldProductsGrowth: 0,
        transfersGrowth: 0
      },
      hasError: false,
      loading: false
    }
  },
  methods: {
    async fetchStats () {
      try {
        this.hasError = false
        this.loading = true

        const { data } = await http.get(`/v1/stores/${this.currentStoreId}/dashboard/stats`)
        this.data = { ...this.data, ...data }
      } catch {
        this.hasError = true
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    await this.fetchStats()
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 30px;
}

.growth {
  &.positive-growth::before {
    content: '+';
  }

  &::after {
    content: '%';
  }
}
</style>
