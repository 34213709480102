<template>
  <div class="card card-message flex-fill w-100">
    <div class="card-header">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <h5 class="card-title mb-0 mr-2">
            {{ title }}
          </h5>
        </div>
        <router-link :to="href" class="btn btn-sm btn-primary btn-header">
          {{ linkLabel }}
        </router-link>
      </div>
    </div>
    <div class="card-body px-0 d-flex justify-content-between flex-column">
      <div class="body">
        <alert v-if="errorMessage" type="danger" class="mx-3">
          {{ errorMessage }}
        </alert>

        <template v-if="loading">
          <skeleton-loader variant="text" width="70%" height="16px" class="position-relative loader" />
          <skeleton-loader variant="text" width="30%" height="16px" class="position-relative loader" />
        </template>

        <span v-else-if="isEmpty" class="text-muted fw-400 font-italic empty-message">
          {{ emptyMessage }}
        </span>

        <template v-else>
          <div v-for="item in data"
               :key="item.id"
               class="flex-grow-1 cursor-pointer pt-1 pb-3 message"
               @click="$emit('navigate', item)">
            <div class="d-flex align-items-baseline justify-content-between">
              <div class="d-flex align-items-center">
                <span class="font-weight-bold">
                  {{ item.title }}
                  <span v-if="item.subtitle" class="ml-1 text-muted small">({{ item.subtitle }})</span>
                </span>
              </div>
              <small class="d-flex text-muted">{{ $longDateTime(item.createdAt) }}</small>
            </div>

            <div class="border-dashed text-sm p-2 mt-1 text-muted d-flex">
              <span class="mr-2 font-weight-bold text-nowrap">{{ item.label }}</span>
              <span>{{ typeof item.message === 'function' ? item.message() : item.message }}</span>
            </div>
          </div>

          <div v-if="+total > data.length" class="text-center mt-4 w-100">
            <router-link :to="href" class="text-muted">
              <u>{{ itemLinkLabel }}</u>
            </router-link>
          </div>
        </template>
      </div>

      <div class="footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import formatsDate from '@/mixins/formatsDate'
import Alert from '@/components/common/Alert'
import SkeletonLoader from '@/components/common/SkeletonLoader'

export default {
  mixins: [formatsDate],
  components: {
    SkeletonLoader,
    Alert
  },
  props: {
    title: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    linkLabel: {
      type: String,
      required: true
    },
    isEmpty: {
      type: Boolean,
      required: true
    },
    itemLinkLabel: {
      type: String,
      required: true
    },
    emptyMessage: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      required: false
    },
    total: {
      type: [Number, String],
      required: false,
      default: 0
    },
    data: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    loading: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.card-message {
  .body {
    overflow-y: auto;
  }
}

.loader {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.empty-message, .message {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.message {
  transition: background 0.3s ease-out;

  &:hover {
    background: whitesmoke;
  }
}
.card-header {
  text-transform: unset;
}

.btn-header {
  font-size: 0.85em;
}
.footer {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.border-dashed {
  border: 1px dashed rgba(0, 0, 0, 0.125);
}
</style>
