<template>
  <div class="card flex-fill">
    <div class="card-body p-0 d-flex flex-fill">
      <div class="row w-100">
        <div class="col-6">
          <div class="p-3 m-1">
            <h4>Re-bienvenue, </h4>
            <h3 class="font-weight-bold">{{ storeName }}!</h3>

            <a :href="storeFrontUrl" target="_blank" class="d-block mt-2 small text-secondary">
              <span class="pr-2">Visiter le site en ligne</span>
              <fa-icon :icon="['fas', 'external-link-alt']" />
            </a>

            <div class="mt-3">
              <router-link to="/account" class="d-block mt-2">
                <fa-icon :icon="['fas', 'user']" class="mr-3" />
                <span>Mon compte</span>
              </router-link>
              <router-link to="/settings/public-profile" class="d-block mt-2">
                <fa-icon :icon="['fas', 'user-tie']" class="mr-3" />
                <span>Profil public</span>
              </router-link>
              <router-link to="/settings/shipping" class="d-block mt-2">
                <fa-icon :icon="['fas', 'truck']" class="mr-3" />
                <span>Frais de port</span>
              </router-link>
              <router-link to="/settings/taxes" class="d-block mt-2">
                <fa-icon :icon="['fas', 'money-bill']" class="mr-3" />
                <span>Gestion des taxes</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-6 d-flex align-items-center justify-content-center flex-column">
          <img class="img-fluid illustration" src="@/assets/images/icons/store.svg" alt="Welcome!">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import onboarding from '@/mixins/onboarding'
import userData from '@/mixins/user-data'

export default {
  mixins: [onboarding, userData],
  computed: {
    storeName () {
      return this.currentStore.name
    },
    storeUrl () {
      return this.currentStore.slug
    },
    storeFrontUrl () {
      return `${config.app.frontUrl}/boutique/${this.storeUrl}`
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 30px;
}
.illustration {
  width: 100%;
  max-width: 260px;
}
</style>
