<template>
  <div class="card flex-fill">
    <div class="card-body p-0 d-flex flex-fill">
      <div class="row w-100">
        <div class="col-6">
          <div class="p-3 m-1">
            <h4>Bienvenue, </h4>
            <h3 class="font-weight-bold">{{ storeName }}!</h3>

            <div class="mt-3">
              <p>Afin de compléter la mise en place de votre boutique, nous devons vous poser quelques questions sur votre entreprise.</p>
              <p>Le processus ne devrait pas prendre plus que quelques minutes.</p>
              <p>Vous pourrez également compléter ces étapes ultérieurement afin de découvrir notre plateforme dès maintenant!</p>
            </div>

            <button class="btn btn-large btn-primary mt-4" @click="handleStart">{{ ctaLabel }}</button>
          </div>
        </div>
        <div class="col-6 d-flex align-items-center justify-content-center flex-column">
          <img class="img-fluid illustration" src="@/assets/images/icons/profile.svg" alt="Welcome!">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import onboarding from '@/mixins/onboarding'
import userData from '@/mixins/user-data'

export default {
  mixins: [onboarding, userData],
  computed: {
    storeName () {
      return this.currentStore.name
    },
    ctaLabel () {
      return this.currentStep === 'legalProfile' ? 'Commencer' : 'Continuer'
    }
  },
  methods: {
    handleStart () {
      this.goToStepUrl()
    }
  }
}
</script>
