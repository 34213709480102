<template>
  <div class="alert" :class="{ 'alert-danger': type === 'danger', 'alert-warning': type === 'warning', 'alert-info': type === 'info', 'alert-success': type === 'success' }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-warning {
  border-color: #ffe58f;
  background: #fffbe6;
}
.alert-info {
  border-color: #9ecae1;
  color: #3182bd;
  background: #e6f7ff;
}

</style>
