<template>
  <div class="card flex-fill w-100">
    <skeleton-loader v-if="!isInitiated && loading" width="100%" height="100%" />
    <div class="card-body py-4">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <div class="d-flex align-items-center">
          <h4 class="mr-1">Revenus / Commandes expédiées</h4>
          <icon-tooltip icon="info-circle" placement="topright">
            <div class="text-left">
             <span class="d-block my-1">
              Revenus : la somme des revenus à la date de transfert
            </span>
            <span class="d-block my-1">
              Commandes expédiées : la somme des commandes à la date d'envoi
            </span>
            </div>
          </icon-tooltip>
        </div>
        <b-dropdown id="graph-period-selector"
                    right
                    variant="outline-primary"
                    size="sm"
                    :text="dropdownLabel"
                    :disabled="loading">
          <b-dropdown-item :active="granularity === 'monthly'" @click="handleChange('monthly')">
            Mois
          </b-dropdown-item>
          <b-dropdown-item :active="granularity === 'weekly'" @click="handleChange('weekly')">
            Semaines
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <alert v-if="hasError" type="danger" class="mx-3">
        Une erreur s'est produite lors du chargement des données de vos revenus et/ou commandes. Veuillez réessayer.
      </alert>

      <template v-if="!loading && isEmpty">
        <div class="empty-graph d-flex flex-column align-items-center justify-content-center">
          <img class="img-fluid" src="@/assets/images/icons/graph.svg" alt="Welcome!">
          <p class="mt-5 mb-0">
            Les données analytiques de vos revenus et commandes s'afficheront ici!
          </p>
        </div>
      </template>

      <template v-else>
        <line-chart :loading="loading" width="100%" height="364px" :chart-data="lineData" :options="options" />
      </template>
    </div>
  </div>
</template>

<script>
import http from '@/utils/http'
import Alert from '@/components/common/Alert'
import LineChart from '@/components/common/charts/LineChart'
import SkeletonLoader from '@/components/common/SkeletonLoader'
import IconTooltip from '@/components/common/IconTooltip'
import userData from '@/mixins/user-data'
import formatsDate from '@/mixins/formatsDate'

export default {
  mixins: [userData, formatsDate],
  components: {
    Alert,
    IconTooltip,
    LineChart,
    SkeletonLoader
  },
  data () {
    return {
      granularity: 'monthly',
      revenues: [],
      orders: [],
      hasError: false,
      loadingOrders: false,
      loadingRevenues: false,
      isOrdersInitiated: false,
      isRevenuesInitiated: false
    }
  },
  computed: {
    loading () {
      return this.loadingOrders || this.loadingRevenues
    },
    isInitiated () {
      return this.isOrdersInitiated && this.isRevenuesInitiated
    },
    isEmpty () {
      return this.revenues.length + this.orders.length <= 0
    },
    dropdownLabel () {
      return this.granularity === 'monthly' ? 'Mois' : 'Semaines'
    },
    currentLabels () {
      return this.orders.map((order) => this.granularity === 'monthly'
        ? this.$monthDate(order.date)
        : this.$weekDate(order.date))
    },
    lineData () {
      return {
        labels: this.currentLabels,
        datasets: [
          {
            label: 'Revenus ($)',
            data: this.revenues.map((revenue) => revenue.total),
            borderColor: '#3182bd',
            backgroundColor: '#3182bd',
            yAxisID: 'y'
          },
          {
            label: 'Commandes',
            data: this.orders.map((order) => order.total),
            borderColor: '#9ecae1',
            backgroundColor: '#9ecae1',
            yAxisID: 'y1'
          }
        ]
      }
    },
    options () {
      return {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false
        },
        stacked: false,
        plugins: {
          legend: {
            position: 'top'
          }
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left'
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false
            }
          },
          x: {
            grid: {
              display: false
            }
          }
        }
      }
    }
  },
  methods: {
    handleChange (granularity) {
      this.granularity = granularity
      this.fetchOrdersOverview()
      this.fetchRevenuesOverview()
    },
    async fetchOrdersOverview () {
      try {
        this.hasError = false
        this.loadingOrders = true

        const { data } = await http.get(
          `/v1/stores/${this.currentStoreId}/dashboard/orders-overview`,
          { params: { granularity: this.granularity } }
        )

        this.orders = data
      } catch {
        this.hasError = true
        this.orders = []
      } finally {
        this.loadingOrders = false
        this.isOrdersInitiated = true
      }
    },
    async fetchRevenuesOverview () {
      try {
        this.hasError = false
        this.loadingRevenues = true

        const { data } = await http.get(
          `/v1/stores/${this.currentStoreId}/dashboard/transfers-overview`,
          { params: { granularity: this.granularity } }
        )

        this.revenues = data
      } catch {
        this.hasError = true
        this.revenues = []
      } finally {
        this.loadingRevenues = false
        this.isRevenuesInitiated = true
      }
    }
  },
  async created () {
    await this.fetchOrdersOverview()
    await this.fetchRevenuesOverview()
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 30px;
  min-height: 475px;
}
.graph-tabs {
  font-size: 0.85em
}
.empty-graph {
  min-height: 350px !important;

  img {
    max-width: 320px !important;
  }
}
</style>
