<template>
  <alert v-if="hasSkippedOnboarding" class="d-flex align-items-center mb-3" type="warning">
    <fa-icon :icon="['fas', 'eye-slash']" class="mr-3" />
    <div>
      <p class="mb-0">
        Votre boutique est en mode brouillon et n'est pas visible publiquement. Vous devez finaliser les étapes d'inscription pour publier votre boutique.
      </p>
      <a href="#" @click="handleSubscription"><u>Compléter mon inscription</u></a>
    </div>
  </alert>
</template>

<script>
import http from '@/utils/http'
import Alert from '@/components/common/Alert'
import onboarding from '@/mixins/onboarding'
import userData from '@/mixins/user-data'

export default {
  mixins: [onboarding, userData],
  components: {
    Alert
  },
  methods: {
    async handleSubscription () {
      try {
        await http.patch(`/v1/stores/${this.currentStoreId}/skip-onboarding`, { hasSkippedOnboarding: false })
        this.$store.commit('SKIP_REGISTRATION', false)
        this.goToStepUrl()
      } catch {
        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    }
  }
}
</script>
