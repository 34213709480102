<template>
  <div class="card flex-fill">
    <skeleton-loader v-if="loading" width="100%" height="100%" />
    <div class="card-body p-0">
      <div class="row w-100 h-100">
        <div class="col-12" :class="{ 'col-12': isEmpty }">
          <div class="p-3 m-1 h-100 d-flex flex-column justify-content-between">
            <div>
              <h4 class="mb-3">Produits</h4>

              <template v-if="hasError">
                <alert type="danger" class="mx-3">
                  Une erreur s'est produite lors du chargement des données de vos produits. Veuillez réessayer.
                </alert>
                <div class="w-100 d-flex flex-column align-items-center">
                  <img class="error-image mt-3" src="@/assets/images/icons/error.svg" alt="" />
                </div>
              </template>

              <div v-else-if="!loading && isEmpty" class="w-100 h-100 m-auto pb-5 d-flex flex-column align-items-center justify-content-center pb-3">
                <img class="empty-image mt-3" src="@/assets/images/icons/product.svg" alt="" />
                <label class="empty-label text-center d-block mb-0">Remplissez votre inventaire dès maintenant!</label>
                <router-link to="/products" class="btn btn-primary btn-sm">Créez votre premier produit</router-link>
              </div>

              <div v-else class="row d-flex">
                <div class="col-6 d-flex flex-column flex-xxl-row align-items-center">
                  <div class="mb-3">
                    <div class="d-flex justify-content-between mb-1">
                      <div class="d-flex align-items-center mr-2">
                        <span class="dot primary" />
                        <span>Publiés</span>
                      </div>
                      <div>{{ data.published }}</div>
                    </div>
                    <div class="d-flex justify-content-between mb-1">
                      <div class="d-flex align-items-center mr-2">
                        <span class="dot secondary" />
                        <span class="fw-semi-bold">Brouillons</span>
                      </div>
                      <div>{{ data.drafts }}</div>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex align-items-center mr-2">
                        <span class="dot tertiary" />
                        <span class="fw-semi-bold">Archivés</span>
                      </div>
                      <div>{{ data.archived }}</div>
                    </div>
                  </div>
                  <doughnut-chart :loading="loading" class="chart-container" :chartData="doughnutData" />
                </div>
                <div class="col-6 d-flex flex-column flex-xxl-row align-items-center">
                  <div class="mb-3">
                    <div class="d-flex justify-content-between mb-1">
                      <div class="d-flex align-items-center mr-3">
                        <span class="dot primary" />
                        <span>Avec variantes</span>
                      </div>
                      <div>{{ data.variants }}</div>
                    </div>
                    <div class="d-flex justify-content-between mb-1">
                      <div class="d-flex align-items-center mr-3">
                        <span class="dot secondary" />
                        <span class="fw-semi-bold">Simples</span>
                      </div>
                      <div>{{ data.simples }}</div>
                    </div>
                  </div>
                  <bar-chart :loading="loading" class="chart-container" :chartData="barData" />
                </div>
              </div>
            </div>

            <div v-if="!isEmpty" class="mb-0 mt-2 small">
              <router-link to="/products">
                <fa-icon :icon="['fas', 'link']" class="mr-2" /> <span>Voir les produits</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/http'
import userData from '@/mixins/user-data'
import Alert from '@/components/common/Alert'
import BarChart from '@/components/common/charts/BarChart'
import DoughnutChart from '@/components/common/charts/DoughnutChart'
import SkeletonLoader from '@/components/common/SkeletonLoader'

export default {
  mixins: [userData],
  components: {
    Alert,
    BarChart,
    DoughnutChart,
    SkeletonLoader
  },
  data () {
    return {
      data: {
        published: 0,
        drafts: 0,
        archived: 0,
        variants: 0,
        simples: 0
      },
      hasError: false,
      loading: false
    }
  },
  computed: {
    isEmpty () {
      return this.data.published + this.data.drafts + this.data.archived <= 0
    },
    doughnutData () {
      return {
        datasets: [{
          data: [this.data.published, this.data.drafts, this.data.archived],
          fill: true,
          borderColor: ['#3182bd', '#9ecae1', '#deebf7'],
          backgroundColor: ['#3182bd', '#9ecae1', '#deebf7'],
          cutout: 50
        }]
      }
    },
    barData () {
      return {
        labels: [''],
        datasets: [
          {
            data: [this.data.variants],
            borderColor: '#3182bd',
            backgroundColor: '#3182bd',
            borderSkipped: false,
            borderRadius: 2
          },
          {
            data: [this.data.simples],
            borderColor: '#9ecae1',
            backgroundColor: '#9ecae1',
            borderSkipped: false,
            borderRadius: 2
          }
        ]
      }
    }
  },
  methods: {
    async fetchProductsOverview () {
      try {
        this.hasError = false
        this.loading = true

        const { data } = await http.get(`/v1/stores/${this.currentStoreId}/dashboard/products-overview`)
        this.data = { ...this.data, ...data }
      } catch {
        this.hasError = true
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    await this.fetchProductsOverview()
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 30px;
}
.dot {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;

  &.primary {
    background: #3182bd;
  }

  &.secondary {
    background: #9ecae1;
  }

  &.tertiary {
    background: #deebf7;
  }

  &.danger {
    background: #fc9272;
  }
}

.empty-image {
  width: 100px;
  max-width: 100px;
  text-align: center;
}

.error-image {
  max-width: 160px;
  text-align: center;
}

.empty-label {
  padding: 1rem 0;
  font-size: 1.1em;
}

.chart-container {
  width: 120px;
  height: 120px;
}

@media (min-width: 1470px) {
  .chart-container {
    width: 160px;
    height: 160px;
  }
}
</style>
