<template>
  <alert v-if="isSettingsConfigured === false" class="d-flex align-items-center mb-3" type="warning">
    <fa-icon :icon="['fas', 'truck']" class="mr-3" />
    <div>
      <p class="mb-0">
        Les frais de port de votre boutique ne sont pas configurés!
      </p>
      <router-link to="/settings/shipping"><u>Configurez-les maintenant</u></router-link>
    </div>
  </alert>
</template>

<script>
import Alert from '@/components/common/Alert'
import onboarding from '@/mixins/onboarding'
import userData from '@/mixins/user-data'
import http from '@/utils/http'
import { isEmpty, pick } from 'lodash-es'

export default {
  mixins: [onboarding, userData],
  components: {
    Alert
  },
  data () {
    return {
      isSettingsConfigured: null
    }
  },
  methods: {
    async fetchSettings () {
      try {
        // check if user has ever configured shipping settings
        const { data } = await http.get(`/v1/stores/${this.currentStoreId}/settings`)
        const shippingSettings = pick({
          ...data
        }, ['shippingPricePolicy', 'shippingPriceAmount', 'freeShippingThreshold'])

        this.isSettingsConfigured = !isEmpty(shippingSettings) && Object.values(shippingSettings).some(setting => !isEmpty(setting))
      } catch {
        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    }
  },
  async created () {
    await this.fetchSettings()
  }
}
</script>
