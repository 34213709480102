<template>
  <span
    :style="{ height, width: computedWidth, borderRadius: computedRadius }"
    class="skeleton-container"
  />
</template>

<script>
export default {
  props: {
    maxWidth: {
      default: 100,
      type: Number
    },
    minWidth: {
      default: 80,
      type: Number
    },
    height: {
      default: '1em',
      type: String
    },
    width: {
      default: null,
      type: String
    },
    variant: {
      type: String,
      required: false,
      default: 'box',
      validator: value => ['box', 'text', 'avatar'].includes(value)
    }
  },
  computed: {
    computedWidth () {
      return this.width || `${Math.floor((Math.random() * (this.maxWidth - this.minWidth)) + this.minWidth)}%`
    },
    computedRadius () {
      switch (this.variant) {
        case 'text':
          return '8px'
        case 'avatar':
          return '50%'
        case 'box':
        default:
          return '6px'
      }
    }
  }
}
</script>

<style lang="scss">
.skeleton-container {
  display: inline-block;
  position: absolute;
  z-index: 99;
  overflow: hidden;
  vertical-align: middle;
  background-color: #e0e0e0;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 5s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
