<template>
  <statistic-card :has-error="hasError" :loading="loading">
    <template v-slot:header>
      <h4 class="mb-2" :class="{ 'text-muted': !connectedStore }">
        {{ connectedStoreName }}
      </h4>
    </template>

    <template v-slot:subtitle>
      Connexion externe
    </template>

    <template v-slot:footer>
      <router-link v-if="!connectedStore" to="/connected-store" class="small">
        <fa-icon :icon="['fas', 'link']" class="mr-2" /> <span>Synchroniser mon inventaire</span>
      </router-link>
      <label v-else class="badge mb-0" :class="connectedStoreStatus.class">
        <span class="align-middle">{{ connectedStoreStatus.name }}</span>
      </label>
    </template>

    <template v-slot:icons>
      <fa-icon :icon="['fas', 'plug']" size="lg" />
    </template>
  </statistic-card>
</template>

<script>
import http from '@/utils/http'
import StatisticCard from '@/components/common/StatisticCard'
import userData from '@/mixins/user-data'

export default {
  mixins: [userData],
  components: {
    StatisticCard
  },
  data () {
    return {
      connectedStore: null,
      hasError: false,
      loading: false
    }
  },
  computed: {
    connectedStoreName () {
      if (!this.connectedStore) {
        return 'Aucune'
      }

      switch (this.connectedStore.provider) {
        case 'shopify':
          return 'Shopify'
        case 'woocommerce':
          return 'WooCommerce'
        default:
          return 'Aucune'
      }
    },
    connectedStoreStatus () {
      if (!this.connectedStore) {
        return {}
      }

      switch (this.connectedStore.data.status) {
        case 'ok':
          return {
            name: 'Connexion active',
            class: 'badge-success'
          }
        case 'invited':
          return {
            name: 'Action requise',
            class: 'badge-warning'
          }
        case 'initiated':
        default:
          return {
            name: 'En cours',
            class: 'badge-info'
          }
      }
    }
  },
  methods: {
    async fetchConnectedStore () {
      try {
        this.loading = true
        this.hasError = false

        const { data } = await http.get(`/v1/stores/${this.currentStoreId}/connected-store`)

        if (!data || !data.id) {
          return
        }

        this.connectedStore = data
      } catch {
        this.hasError = true
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    await this.fetchConnectedStore()
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 30px;
}
</style>
